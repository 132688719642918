'use client';

import { ErrorPlaceholder } from '@/components/placeholders';
import { logger } from '@/logger';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  console.log('error', error);
  logger.error('Error happened');
  return <ErrorPlaceholder onClick={() => reset()} />;
}
